import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Stats from '../pages/Stats.vue'
import MyFavorites from '../pages/MyFavorites.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
  {
    path: '/my-favorites',
    name: 'MyFavorites',
    component: MyFavorites
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
