<template>
  <div class="page-container">
    <div class="page-column-container">
      <div class='labeled-container-wrapper'>
        <div class = "labeled-container shadow">
          <h1>About</h1>
    
          <p>Clumsyghosts.com is an unofficial tool to search, filter and compare your favorite clumsy ghosts. 
            On the homepage you can filter clumsys by multiple traits, or search for a specific clumsy in the search bar.
            The stats tab shows how many clumsys exist with certain traits. The 'My favorites' tab allows you to easily compare 
            your favorite clumsys by dragging them into the indicated comparison containers. Clumsys can be set as favorite from the home page.
            If I can get acces to the cnft and/or jpegstore api I will fill the sales and price section in the data tab.
          </p>
          <p>I want to thank bkc#1011 (@discord) for providing all the latest data and of of course the clumyghosts team @<a href='https://www.clumsyghosts.com/'> https://www.clumsyghosts.com/</a> for creating such an amazing project. </p>

          <h3>About me</h3>
          <p>I'm a self thought front-end developer looking to make a career switch into software development. One of the reasons for building this website was to extend my portfolio.</p>
          <p>If you have any questions feel free to shoot me a message on discord: foxdaddy#0698</p>
          <p>Enjoyed this tool? Consider buying me a coffee @yoroi: </p>
          <p class = 'word-break'>addr1qyw6eka6p67uxgcxlr3cr23j29h34k9e6k3a67306u6ufynz52y6eg09lplru2g4m2q3nzjuuck7h682nep5pcs8tflqrmq29l</p>  
          <img class='wallet-qr' src='../assets/wallet.png' alt=''/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .wallet-qr{
    width: 10em;
    height: auto;
  }
  .word-break{
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
</style>
